import React, {useState, useEffect, useRef} from 'react';
import {darkStyles, lightStyles} from "./GoogleMapsStyles.jsx";
import cx from "classnames";
import { useLocation, useSearchParams } from "react-router-dom";
import querystring from 'querystring'
import {useDebounce} from "../utils/debounce.jsx";

const computeDistance = (lat1,lng1, lat2,lng2) => {

    let src_coordinates = new google.maps.LatLng(lat1, lng1);
    let dst_coordinates = new google.maps.LatLng(lat2, lng2);
    let dist = google.maps.geometry.spherical.computeDistanceBetween(src_coordinates, dst_coordinates);

    dist = Math.round(dist/100)/10; //round to 1 decimal
    return dist;
}



const Location = props => {
    const {location}  = props;
    return (
        <div
            onClick={e => props?.setActiveMarker && props.setActiveMarker(location.url)}
        >
            <div className="map-location__header">
                <div className="map-location__header-text">
                    <h2 className="heading-delta">
                        {location.name}
                    </h2>
                    <span className="map-location__distance">{location?.distance > 0 && location?.distance < 999 &&  <> {location.distance} km</>}</span>
                </div>
                {props?.close && typeof props.close === 'function' && (
                    <button onClick={e => {e.preventDefault(); props.close()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="map-location__close">
                            <use className="no-outline" href="/static/images/symbols.svg#cross"/>
                        </svg>
                    </button>
                )}
            </div>
            <div className="map-location__info">
                <address>
                    <div className="map-location__address">
                        <svg xmlns="http://www.w3.org/2000/svg" className="map-location__icon" role="img">
                            <title>Adres</title>
                            <use className="no-outline" href="/static/images/symbols.svg#map-pin-filled"/>
                        </svg>
                        <div>
                            <p>
                                {location.address || ""}<br/>
                                {location.postal_code || ""} {location.city || ""}
                            </p>
                        </div>
                    </div>
                    {location.telephone && (
                        <div className="map-location__address">
                            <svg xmlns="http://www.w3.org/2000/svg" className="map-location__icon" role="img">
                                <title>Telefoonnummer</title>
                                <use className="no-outline" href="/static/images/symbols.svg#phone-filled"/>
                            </svg>
                            <a href="tel:#"> {location.telephone} </a>
                        </div>
                    )}
                    {location.waitingTime && (
                        <div className="map-location__address">
                            <svg xmlns="http://www.w3.org/2000/svg" className="map-location__icon" role="img">
                                <title>Wachttijd</title>
                                <use className="no-outline" href="/static/images/symbols.svg#clock"/>
                            </svg>
                            {location.waitingTime}
                        </div>
                    )}
                </address>
                <a className="chevron-link" href={location.url}
                >
                    Bekijk&nbsp;locatie
                </a>
            </div>
        </div>
    )
}

export const ReactGoogleMaps = (props) => {

    const location = useLocation();
    const [filterParams, setFilterParams] = useSearchParams();
    // additionalParams could be UTM codes
    const [additionalParams, setAdditionalParams] = useState({});
    const [interacted, setInteracted] = useState(false);

    const [ apiLoaded, setApiLoaded ] = useState(false);
    const [ config, setConfig] = useState(false);
    const [ map, setMap ] = useState(false);
    const [ geocoder, setGeocoder] = useState(false);

    const [ filterActive, setFilterActive] = useState(false);
    const [ currentMarkers, setCurrentMarkers ] = useState([]);
    const [ theme , setTheme ] = useState("light");

    const [ careAndLivingCount, setCareAndLivingCount] = useState(0);
    const [ daytimeActivitiesCount, setDaytimeActivitiesCount ] = useState(0);
    const [ therapyCount, setTherapyCount] = useState(0);
    const [ directlyAvailableCount, setDirectlyAvailableCount] = useState(0);

    const [ accentColor, setAccentColor] = useState(false);
    const [ markerFillColor, setMarkerFillColor ] = useState(false);
    const [ strokeColor, setStrokeColor] = useState(false);
    const [ activeStrokeColor, setActiveStrokeColor] = useState(false);

    const [activeMarker, setActiveMarker] = useState(false);
    const [mapActive, setMapActive] = useState(true);

    const [all, setAll] = useState(true);

    // We need to check if urlParams are present, if they are the defaultFilters should not be used at all.
    const [careAndLiving, setCareAndLiving] = useState(location.search?.length === 0 ? props?.defaultFilters?.split(",")?.includes("careAndLiving") : false);
    const [daytimeActivities, setDaytimeActivities] = useState(location.search?.length === 0 ? props?.defaultFilters?.split(",")?.includes("daytimeActivities") : false);
    const [therapy, setTherapy] = useState(location.search?.length === 0 ? props?.defaultFilters?.split(",")?.includes("therapy") : false);

    const [directlyAvailable, setDirectlyAvailable] = useState(false);

    const [small, setSmall] = useState(props?.fullWidth === "False");
    const [filterChecked, setFilterChecked] = useState(true);

    const [ searchVal, setSearch] = useState("")
    const [ searchLocation, setSearchLocation] = useState({});
    const search = useDebounce(searchVal, 500);

    // LookUpTable for distances
    const [LUT, setLUT] = useState({});

    const filterItems = [careAndLiving, daytimeActivities, therapy];
    const defaultFilterItemStrings = ['all', 'careAndLiving', 'directlyAvailable', 'daytimeActivities', 'therapy'];

    const currentMarker = activeMarker ? currentMarkers?.find(o => o?.extraData?.url === activeMarker) : false;

    useEffect(() => {
        // make sure when a default url is passed that when the markers are loaded, the activemarker is set
        if(config?.centerCoords?.url && currentMarkers?.length > 0) {
            setActiveMarker(config?.centerCoords?.url);
        }
    },[config?.centerCoords?.url, currentMarkers]) ;

    useEffect(() => {
        // Set the state of the filters with the urlParams if they have been set
        if (location.search?.length > 0) {
            const rawUrlParams = location.search.split(/[?&]/);
            const urlParams = {};
            const additionalParams = {};

            rawUrlParams.forEach((value) => {
                let param = value.split('=');
                if (param.length == 2) {
                    urlParams[param[0]] = param[1];
                    // We need the additional params separately to rebuild the url
                    if (!defaultFilterItemStrings.includes(param[0])) {
                        additionalParams[param[0]] = param[1];
                    }
                }
            });

            setAdditionalParams(additionalParams);

            setCareAndLiving(urlParams["careAndLiving"] === 'true');
            setDaytimeActivities(urlParams["daytimeActivities"] === 'true');
            setTherapy(urlParams["therapy"] === 'true');
            setDirectlyAvailable(urlParams["careAndLiving"] === 'true' && urlParams["directlyAvailable"] === 'true');
        }
    }, []);

    const resetFilters = () =>{
        setCareAndLiving(false);
        setDaytimeActivities(false);
        setTherapy(false);
        setDirectlyAvailable(false);
    }

    const countLocationAmounts = (locations) => {
        setCareAndLivingCount(locations?.filter( location => location.careAndLiving === 'True').length);
        setDaytimeActivitiesCount(locations?.filter( location => location.daytimeActivities === 'True').length);
        setTherapyCount(locations?.filter( location => location.therapy === 'True').length);
        setDirectlyAvailableCount(locations?.filter( location => location.directlyAvailable === 'True').length);
    }

    const filteredLocations = () => {
        const locations = config.locations?.filter( location =>
            (all)
            ||
            (careAndLiving && !directlyAvailable && location.careAndLiving === "True")
            ||
            (careAndLiving && directlyAvailable && location.careAndLiving === "True" && location.directlyAvailable === "True")
            ||
            (daytimeActivities && location.daytimeActivities === "True")
            ||
            (therapy && location.therapy === "True")
        ).map( location => {
            if(search !== "" && location?.name && location.name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase())) {
                return {
                    ...location,
                    distance: -1,
                };
            } else {
                return {
                    ...location,
                    distance: LUT[parseFloat(location?.lat.replace(",",".")) + '+' + parseFloat(location?.lng.replace(",","."))] || 999,
                }
            }
        })
        locations?.sort((a,b) => {
            return a.distance < b.distance ? -1 : b.distance < a.distance  ? 1 : 0;

        });

        return locations;
    }

    const ref = useRef(null);

    const filterOptions = [
        {
            title: "Alle locaties",
            identifier: "all",
        },
        {
            title: "Wonen met zorg",
            identifier: "careAndLiving",
        },
        {
            title: "Behandeling",
            identifier: "therapy",
        },
        {
            title: "Dagbesteding",
            identifier: "daytimeActivities",
        }
    ]

    // The default SVG Marker which is used for google maps markers
    const svgMarker = (accentColor, markerFillColor) => {
        return {
            // path: "M28.8,14.4 C28.8,25.6 14.4,35.2 14.4,35.2 C14.4,35.2 0,25.6 0,14.4 C0,6.44709969 6.44709969,2.14576721e-07 14.4,2.14576721e-07 C22.3529003,2.14576721e-07 28.8,6.44709969 28.8,14.4 L28.8,14.4 Z",
            // fillColor: fill,
            // strokeColor: stroke,
            url: 'data:image/svg+xml;UTF-8,' +
                encodeURIComponent('<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"> \
                    <path stroke="'+ strokeColor + '" stroke-width="1px" fill="'+ accentColor + '" d="M33.5 17C33.5 27.5 20 36.5 20 36.5S6.5 27.5 6.5 17C6.5 9.544 12.544 3.5 20 3.5S33.5 9.544 33.5 17z"/>\
                    <path fill="'+ strokeColor +'" d="M15,17a5,5 0 1,0 10,0a5,5 0 1,0 -10,0"/>\
                </svg>'),
            fillOpacity: 1,
            strokeWeight: 7,
            rotation: 0,
            scale: 0.75,
            anchor: new google.maps.Point(15, 30)
        }
    };

    // The default SVG Marker which is used for google maps markers
    const currentSvgMarker = (accentColor, markerFillColor) => {
        return {
            // path: "M28.8,14.4 C28.8,25.6 14.4,35.2 14.4,35.2 C14.4,35.2 0,25.6 0,14.4 C0,6.44709969 6.44709969,2.14576721e-07 14.4,2.14576721e-07 C22.3529003,2.14576721e-07 28.8,6.44709969 28.8,14.4 L28.8,14.4 Z",
            // fillColor: "#0f0",
            // strokeColor: stroke,
            // <path stroke="'+ markerFillColor + '" stroke-width="2px" fill="'+ markerFillColor +'" d="M20 9C13.929 9 9 13.929 9 20s4.929 11 11 11 11-4.929 11-11S26.071 9 20 9zm0 2c4.967 0 9 4.033 9 9s-4.033 9-9 9-9-4.033-9-9 4.033-9 9-9z"/> \
            url: 'data:image/svg+xml;UTF-8,' +
                encodeURIComponent('<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"> \
                    <path stroke="'+ activeStrokeColor + '" stroke-width="1px" fill="'+ markerFillColor + '" d="M33.5 17C33.5 27.5 20 36.5 20 36.5S6.5 27.5 6.5 17C6.5 9.544 12.544 3.5 20 3.5S33.5 9.544 33.5 17z"/>\
                    <path fill="'+ activeStrokeColor +'" d="M15,17a5,5 0 1,0 10,0a5,5 0 1,0 -10,0"/>\
                </svg>'),
            fillOpacity: 1,
            strokeWeight: 7,
            rotation: 0,
            scale: 0.75,
            anchor: new google.maps.Point(15, 30)
        }
    };

    const isDetailView = config?.centerCoords?.url && config?.centerCoords.url !== "";

    // when a searchquery is passed, geocode it to a location and use it
    useEffect(() => {

        if(geocoder) {
            geocoder.geocode({
                address: `${search} Netherlands`,
                language: 'nl'
            }, (res, status) => {

                let result = res[0];
                let center = false;

                // if we selected a region, city or postalcode
                if(result.geometry.bounds) {
                    center = result.geometry.bounds.getCenter();
                } else {
                    // We probably selected a specific street, or something which google thinks is a specific location instead of a region so use the specific place as center
                    center = result.geometry.location;
                }

                let lat = center.lat();
                let lng = center.lng();
                setSearchLocation({lat, lng});

            });
        }

    }, [search]);

    // If a location is found for a given searchquery, use it to create an ordered list based on the distance  from the search query to the location
    useEffect(() => {

        if(searchLocation?.lat && searchLocation?.lng) {

            const bounds = new google.maps.LatLngBounds();

            bounds.extend(new google.maps.LatLng(searchLocation.lat, searchLocation.lng));

            if(config?.locations?.length > 0) {

                const LUT = {};

                config.locations.forEach( location => {

                    const lat = parseFloat(location?.lat.replace(",","."));
                    const lng = parseFloat(location?.lng.replace(",","."));
                    bounds.extend(new google.maps.LatLng(lat, lng));
                    const distance = computeDistance(searchLocation.lat, searchLocation.lng, lat, lng);
                    LUT[`${lat}+${lng}`] = distance;
                });

                setLUT(LUT);

                map.setCenter(bounds.getCenter());
                map.fitBounds(bounds);
                map.panToBounds(bounds);
            }
        }
    }, [searchLocation?.lat, searchLocation?.lng, config?.locations]);


    useEffect(() => {
        // make sure when a default url is passed that when the markers are loaded, the activemarker is set
        if(config?.centerCoords?.url && currentMarkers?.length > 0) {
            setActiveMarker(config?.centerCoords?.url);
        }
    },[config?.centerCoords?.url, currentMarkers]) ;

    // Load the API after first render
    useEffect(() => {
        var script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=apiLoaded&libraries=places,geometry&v=weekly`;
        script.async = true;
        script.defer = true;
        window.apiLoaded = () => {
            setApiLoaded(true);
        }
        document.head.appendChild(script);

        const targetNode = document.getElementsByTagName('html')[0];
        const mapsElm = document.querySelectorAll('.map__location-wrapper')[0];
        const locations = Array.from(mapsElm.querySelectorAll(".map-location")).map(loc => Object.assign({}, loc.dataset, {innerHTML: loc.innerHTML}));
        const centerCoords = Object.assign({}, document.querySelector('#map-container')?.dataset || {});

        const defaultAccentColor = getComputedStyle(targetNode).getPropertyValue('--color-accent').trim();
        const defaultMarkerFillColor = getComputedStyle(targetNode).getPropertyValue('--color-background-form-input').trim();
        const defaultStrokeColor = getComputedStyle(targetNode).getPropertyValue('--color-background-form-input').trim();
        const defaultActiveStrokeColor = getComputedStyle(targetNode).getPropertyValue('--color-selection-button-active-text').trim();
        const defaultTheme = targetNode.dataset?.theme === "dark" ? "dark": "light";
        const lat = parseFloat(centerCoords?.lat.replace(",","."));
        const lng = parseFloat(centerCoords?.lng.replace(",","."));
        const url = centerCoords?.url;
        const selectedFilters = centerCoords?.defaultFilters?.split(",") || [];

        mapsElm.style.display = "none"; // Used to hide the list once JS is inited (if we cant load map, we display links)

        setConfig({
            defaultAccentColor,
            defaultMarkerFillColor,
            defaultTheme,
            centerCoords: {
                lat,
                lng,
                url,
            },
            locations,
            selectedFilters
        });

        setAccentColor(defaultAccentColor);
        setMarkerFillColor(defaultMarkerFillColor);
        setStrokeColor(defaultStrokeColor);
        setActiveStrokeColor(defaultActiveStrokeColor);

        // We define settings for a MutationObserver which monitors changes in the dom so we can change according to the colortheme/dark-mode
        const config = { attributes: true, childList: false, subtree: false};
        const callback = function(mutationsList, observer) {

            for(const mutation of mutationsList) {
                setTheme(targetNode.dataset?.theme === "dark" ? "dark": "light")
                setAccentColor(getComputedStyle(targetNode).getPropertyValue('--color-accent'));
            }
        }

        // create the actual observer and start observing
        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);

    },[]);

    useEffect(() => {
        if(apiLoaded && config?.defaultTheme) {
            // The single instance of google maps. After creation only this should be used
            setMap(new google.maps.Map(document.getElementById("map"), {
                zoom: (config?.selectedFilters?.length > 0) && !isDetailView ? 8 : isDetailView ? 9.5 : 8.5,
                center: config?.centerCoords,
                mapTypeControl: false,
                zoomControl: true,
                fullscreenControl: false,
                streetViewControl: false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
            }));
            setTheme(config?.defaultTheme);

            setGeocoder(new google.maps.Geocoder());
        }
    }, [apiLoaded, config]);

    useEffect(() => {

        currentMarkers.filter(o => o?.extraData?.url !== activeMarker).forEach(marker => {
            marker.setIcon(Object.assign({}, marker.icon, svgMarker(accentColor, markerFillColor)));
            marker.setZIndex(0);
        });

        if(activeMarker) {
            let currentMarker = currentMarkers?.find(o => o?.extraData?.url === activeMarker);
            currentMarker.setIcon(Object.assign({}, currentMarker.icon, currentSvgMarker(accentColor, markerFillColor)));
            currentMarker.setZIndex(1);
            document.getElementById(currentMarker.extraData.url)?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline:'start'});

            map.panTo(new google.maps.LatLng(currentMarker.position.lat(), currentMarker.position.lng()));
        }

    }, [activeMarker]);


    useEffect(() => {

        if(map) {
            let locationMarkers = [];

            config.locations.map( (location, i) => {
                const lat = parseFloat(location.lat.replace(",","."));
                const lng = parseFloat(location.lng.replace(",","."));

                const marker = new google.maps.Marker({
                    position: {
                        lat,
                        lng,
                    },
                    map: map, //shouldDisplay ? map : null,
                    icon: location.url === config?.centerCoords?.url ? currentSvgMarker(accentColor, markerFillColor) : svgMarker(accentColor, markerFillColor),
                    extraData: location,
                });

                // default z-index
                marker.setZIndex(0);

                // if selected raise the zIndx
                if(location.url === config?.centerCoords?.url) {
                    marker.setZIndex(1);
                }

                marker.addListener("click", () => {
                    setActiveMarker(location.url);
                });

                locationMarkers.push(marker);

            });

            setCurrentMarkers(locationMarkers);
        }

    }, [map, accentColor, markerFillColor]);

    useEffect(() => {
        if(map) {
            map.setOptions({styles: theme === "dark" ? darkStyles : lightStyles});
        }
    },[map, theme]);


    useEffect(() => {
        if(all) {
            if(filterItems.some(o=> o === true)) {
                setAll(false);
            }
        } else {
            if(filterItems.every(o=> o !== true)) {
                setAll(true);
            }
        }

        const filteredLocs = filteredLocations();
        countLocationAmounts(filteredLocs);

        currentMarkers.forEach( marker => {
            const loc = filteredLocs.find(o => o.url === marker.extraData.url);
            if(loc) {
                marker.setVisible(true);
            } else {
                marker.setVisible(false);
            }
        });

        // Make a list of the filters which are active, this list has to be as small as
        // possible so we need to avoid filters which are set as false
        let filterParamItems = {...additionalParams};

        if (all) {filterParamItems["all"] = true}
        if (therapy) { filterParamItems["therapy"] = true}
        if (daytimeActivities) { filterParamItems["daytimeActivities"] = true}

        if (careAndLiving) {
            filterParamItems["careAndLiving"] = true;
            if (directlyAvailable) {
                filterParamItems["directlyAvailable"] = true;
            }
        }

        // Update the url based on the list of active filters
        if (Object.keys(filterParamItems).length > 0 && apiLoaded && interacted) {
            setFilterParams("?" + querystring.stringify(filterParamItems));
        }

    }, [all, careAndLiving, daytimeActivities, therapy, directlyAvailable, currentMarkers]);

    if(!apiLoaded || !config) {
        return <>Loading...</>;
    }

    return (
        <>
            <div className={cx("map", "rs_skip", {"map--small": small})} onClick={() => {setInteracted(true)}}>
                <div className="map__sidebar-wrapper">

                    <div className="map-header">
                        <h3 className="heading-gamma heading--no-margin">Locatie zoeken</h3>
                        <div>
                            <button className={cx("map-header__toggle selection-button", {"selection-button--active": !mapActive})} onClick={() => setMapActive(false)}>Lijst</button>
                            <button className={cx("map-header__toggle selection-button", {"selection-button--active": mapActive})} onClick={() => setMapActive(true)}>Kaart</button>
                        </div>
                    </div>


                    {((small && careAndLiving) || !small ) && (

                        <div className="map-filter">
                            {!small && (
                                <>
                                    <h3 className={cx("map-filter__title", "heading-gamma", {"map-filter__title--open": filterChecked})} onClick={e => { setFilterChecked(!filterChecked) }}>Filter locaties op zorgaanbod</h3>
                                    <ul className={cx("map-filter__list", {"map-filter__list--open": filterChecked})}>
                                        <li className="map-filter__list-item">
                                            <input type="checkbox" className="checkbox" name="all" id="all" checked={all} onChange={() => {
                                                if(filterItems.some(o => o === true)) {
                                                    resetFilters();
                                                } else {
                                                    setAll(true)
                                                }
                                            }} />
                                            <label htmlFor="all">Alle locaties ({config?.locations.length})</label>
                                        </li>
                                        <li className="map-filter__list-item">
                                            <input type="checkbox" className="checkbox" name="therapy" id="therapy" checked={therapy} onChange={() => setTherapy(!therapy)}/>
                                            <label htmlFor="therapy">Behandeling ({therapyCount})</label>
                                        </li>
                                        <li className="map-filter__list-item">
                                            <input type="checkbox" className="checkbox" name="careAndLiving" id="careAndLiving" checked={careAndLiving} onChange={() => setCareAndLiving(!careAndLiving)} />
                                            <label htmlFor="careAndLiving">Wonen met zorg ({careAndLivingCount})</label>
                                        </li>
                                        <li className="map-filter__list-item map-filter__list-item">
                                            <input type="checkbox" className="checkbox" name="daytimeActivities" id="daytimeActivities" checked={daytimeActivities} onChange={() => setDaytimeActivities(!daytimeActivities)}/>
                                            <label htmlFor="daytimeActivities">Begeleiding groep & begeleiding thuis ({daytimeActivitiesCount})</label>
                                        </li>

                                    </ul>
                                </>
                            )}

                            {careAndLiving && (
                                <ul className="map-filter__list map-filter__list--sub-list">
                                    <li className="map-filter__list-item">
                                        <input type="checkbox" className="checkbox" name="directlyAvailable" id="directlyAvailable" checked={directlyAvailable} onChange={() => setDirectlyAvailable(!directlyAvailable)}/>
                                        <label htmlFor="directlyAvailable">Alleen locaties met beschikbare woningen ({directlyAvailableCount})</label>
                                    </li>
                                </ul>
                            )}

                        </div>
                    )}

                    <div className={cx("map__location-wrapper", {"map__location-wrapper--active": !mapActive})}>

                        <input className={"map-search"} type={"text"} name={"search"} placeholder={"Zoeken op plaatsnaam of postcode"} value={searchVal} onChange={e => setSearch(e?.target?.value || "")}/>

                        <ul>
                            {filteredLocations().map( (location, idx) => {
                                return (
                                    <li id={location.url} className={cx("map-location", {"map-location--active": location.url === activeMarker })} key={location.url} tabIndex={0} onKeyDown={e => e.key ==='Enter' && setActiveMarker && setActiveMarker(location.url)}>
                                        <div className="location-box">
                                            <Location
                                                location={location}
                                                setActiveMarker={marker => setActiveMarker(marker)}
                                            />
                                        </div>
                                    </li>
                                )

                            })}
                        </ul>
                    </div>
                </div>

                <div className={cx("map__wrapper", {"map__wrapper--active": mapActive})}>
                    <div id="map" className={cx("google-maps")} />
                    {currentMarker.extraData && (
                        <>
                            <div className="map-location map-location--map-view">
                                <Location
                                    location={currentMarker.extraData}
                                    close={() => setActiveMarker(false)}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
