import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import {ReactGoogleMaps} from "./scripts/components/ReactGoogleMaps.jsx";

// document.addEventListener('DOMContentLoaded', (event) => {

  // load react app later
  const COMPONENTS = {
      ReactGoogleMaps,
  };

  function renderComponentInElement(el) {

      let id = el.dataset['reactComponent'] || false;
      let Component = COMPONENTS[id];

      if (!Component) return;

      // get props from elements data attribute, like the post_id
      let props = Object.assign({}, el.dataset);
      props.childNodes = el.cloneNode(true);

      ReactDOM.render(
          <BrowserRouter>
              <Component {...props} />
          </BrowserRouter>
          ,el
      );
  }

  // populate React components
  Array.from(document.querySelectorAll('.__react-root')).forEach(renderComponentInElement);
// })
