export const ostyles = [
    {
        "featureType":"administrative",
        "elementType":"labels.text.fill",
        "stylers":[
            {
                "color":"#686767"
            }
        ]
    },
    {
        "featureType":"landscape",
        "elementType":"all",
        "stylers":[
            {
                "color":"#dfdbd4"
            }
        ]
    },
    {
        "featureType":"poi",
        "elementType":"all",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road",
        "elementType":"all",
        "stylers":[
            {
                "saturation":-100
            },
            {
                "lightness":45
            }
        ]
    },
    {
        "featureType":"road",
        "elementType":"labels",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road.highway",
        "elementType":"all",
        "stylers":[
            {
                "visibility":"simplified"
            },
            {
                "color":"#f9f8f6"
            }
        ]
    },
    {
        "featureType":"road.highway",
        "elementType":"labels",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road.arterial",
        "elementType":"all",
        "stylers":[
            {
                "weight":"0.98"
            },
            {
                "visibility":"simplified"
            }
        ]
    },
    {
        "featureType":"road.arterial",
        "elementType":"geometry.stroke",
        "stylers":[
            {
                "weight":"1.08"
            }
        ]
    },
    {
        "featureType":"road.arterial",
        "elementType":"labels.text",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road.arterial",
        "elementType":"labels.icon",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road.local",
        "elementType":"all",
        "stylers":[
            {
                "visibility":"simplified"
            },
            {
                "lightness":"81"
            },
            {
                "saturation":"0"
            }
        ]
    },
    {
        "featureType":"road.local",
        "elementType":"labels",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"transit",
        "elementType":"all",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"water",
        "elementType":"all",
        "stylers":[
            {
                "color":"#f8f6f3"
            },
            {
                "visibility":"on"
            }
        ]
    }
];

export const lightStyles = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#686767"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#dfdbd4"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#f9f8f6"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "all",
        "stylers": [
            {
                "weight": "0.98"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "weight": "1.08"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "lightness": "81"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#efede8"
            },
            {
                "visibility": "on"
            }
        ]
    }
];

export const darkStyles = [
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "invert_lightness": true
            },
            {
                "lightness": "22"
            },
            {
                "gamma": "1.30"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#f4eee6"
            },
            {
                "lightness": "-7"
            },
            {
                "saturation": "-54"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#5f5b55"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-100"
            },
            {
                "lightness": "58"
            },
            {
                "invert_lightness": true
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#f9f8f6"
            },
            {
                "invert_lightness": true
            },
            {
                "lightness": "18"
            },
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "all",
        "stylers": [
            {
                "weight": "0.98"
            },
            {
                "lightness": "16"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "weight": "1.08"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "lightness": "18"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#333333"
            },
            {
                "visibility": "on"
            }
        ]
    }
];
